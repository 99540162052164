var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.templates)?_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","block":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.name)+" ")])]}}],null,false,902708151),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticStyle:{"background-color":"#f7f7f7"}},[_c('v-toolbar',{attrs:{"dark":"","color":"primary","elevation":"0"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t('players.template', _vm.locale))+" ")]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" "+_vm._s(_vm.$t('common.close', _vm.locale))+" ")])],1)],1),_vm._l((_vm.templates),function(template){return _c('div',{key:template.ID},[_c('div',{staticStyle:{"padding":"20px","float":"left"}},[_c('v-card',{staticClass:"main-template-preview",style:({ width: (_vm.width + "px") }),attrs:{"elevation":"2"},on:{"click":function($event){return _vm.handleClick(template.ID)}}},[_c('div',{style:({ backgroundColor: template.PrevColor ? template.PrevColor : '#CCCCCC', width: (_vm.width + "px"), height: ((_vm.getSize(1080)) + "px")})},_vm._l((template.Zones),function(zone){return _c('div',{key:("" + (template.ID) + (zone.ID)),staticStyle:{"position":"absolute"},style:({
                    backgroundColor: zone.PrevColor ? zone.PrevColor : 'red',
                    top: ((_vm.getSize(zone.Top)) + "px"),
                    right: ((_vm.getSize(zone.Right)) + "px"),
                    width: ((_vm.getSize(zone.Width)) + "px"),
                    height: ((_vm.getSize(zone.Height)) + "px"),
                  })})}),0),_c('v-card-title',{style:({ color: template.ID === _vm.active ? 'black' : '#c2c2c2' })},[_vm._v(" "+_vm._s(template.Name)+" ")])],1)],1)])})],2)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }