<template>
  <v-row justify="center" v-if="templates">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
          block
        >
          {{name}}
        </v-btn>
      </template>
      <v-card
        style="background-color: #f7f7f7"
      >
        <v-toolbar
          dark
          color="primary"
          elevation="0"
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
              {{ $t('players.template', locale) }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
              @click="dialog = false"
            >
              {{ $t('common.close', locale) }}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
          <div
            v-for="template of templates"
            :key="template.ID"
          >
            <div style="padding: 20px;float: left;">
              <v-card
                elevation="2"
                class="main-template-preview" 
                :style="{ width: `${width}px` }"
                @click="handleClick(template.ID)"
              >
                <div 
                  :style="{ backgroundColor: template.PrevColor ? template.PrevColor : '#CCCCCC', width: `${width}px`, height: `${getSize(1080)}px`}"
                >
                  <div
                    v-for="zone of template.Zones"
                    :key="`${template.ID}${zone.ID}`"
                    style="position:absolute;"
                    :style="{
                      backgroundColor: zone.PrevColor ? zone.PrevColor : 'red',
                      top: `${getSize(zone.Top)}px`,
                      right: `${getSize(zone.Right)}px`,
                      width: `${getSize(zone.Width)}px`,
                      height: `${getSize(zone.Height)}px`,
                    }"
                  />
                </div>

                <v-card-title 
                  :style="{ color: template.ID === active ? 'black' : '#c2c2c2' }"
                >
                  {{ template.Name }}
                </v-card-title>
              </v-card>
            </div>
          </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  props: {
    name: {
      type: String,
      default: null,
    },
    templates: {
      type: Array,
      default: null,
    },
    locale: {
      type: String,
      required: true,
    },
    onClick: {
      type: Function,
      required: true,
    },
    active: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    dialog: false,
    width: 300,
  }),
  mounted () {
  },
  methods: {
    getSize (v) {
      return (this.width * v) / 1920
    },
    handleClick (v) {
      this.onClick(v)
      this.dialog = false
    },
  },
}
</script>

